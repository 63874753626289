// 申遗承诺
import SHZT from './sn-table-column-shzt';
const selfData = {
    loading: false,
    snTable: {

        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    label: "病害名称",
                    prop: "BHMC",
                    minWidth: ""
                },
                {
                    label: "监测点位",
                    prop: "JCWZMC",
                    minWidth: ""
                },
                {
                    label: "评估",
                    prop: "PGNAME",
                    minWidth: ""
                },
                {
                    label: "监测时间",
                    prop: "CJSJ",
                    minWidth: ""
                },
                {
                    label: "数值",
                    prop: "JCZ",
                    minWidth: ""
                },
                {
                    label: "备注",
                    prop: "SM",
                    minWidth: ""
                },
                SHZT,
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;